import { defineStore } from 'pinia';
import { assertIsError } from '~/utils';
import { useAuth } from '~/composables/useAuth';
import type { User } from '~/utils/api-client';

interface AuthState {
  user: User | null;
  error: string | null;
  isLoading: boolean;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: null,
    error: null,
    isLoading: false,
  }),

  getters: {
    isLoggedIn: (state) => state.user !== null,
  },

  actions: {
    async initAuth() {
      const auth = useAuth();
      try {
        this.isLoading = true;
        this.user = await auth.getUser();
      } catch (error) {
        this.user = null;
      } finally {
        this.isLoading = false;
      }
    },

    async login() {
      this.error = null;
      this.isLoading = true;

      const auth = useAuth();
      const { origin } = useRequestURL();
      const successUrl = `${origin}/app/dashboard`;
      const failureUrl = `${origin}/app/login`;

      try {
        await auth.createOAuth2Session(successUrl, failureUrl);
      } catch (error: unknown) {
        assertIsError(error);
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async logout() {
      try {
        const auth = useAuth();
        await auth.logout();
        this.user = null;
      } catch (error: unknown) {
        assertIsError(error);
        this.error = error.message;
      }
    },
  },
});
