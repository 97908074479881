import { createClient, createAuthService } from '~/utils/api-client';

export function useAuth() {
  const config = useRuntimeConfig();

  const client = createClient({
    endpoint: config.public.config.api.endpoint,
    project: config.public.config.api.project,
  });

  return createAuthService(client);
}
