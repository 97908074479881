import { Client, Account, OAuthProvider, type Models } from 'appwrite';

let client: Client | null = null;

export function createClient(config: { endpoint: string; project: string }) {
  if (!client) {
    client = new Client();
    client.setEndpoint(config.endpoint).setProject(config.project);
  }
  return client;
}

export function createAuthService(client: Client) {
  const account = new Account(client);

  return {
    /**
     * Get the currently logged in user.
     *
     * @throws {AppwriteException}
     * @returns {Promise<Models.User<Preferences>>}
     */
    getUser: () => account.get(),

    /**
     * Get the current user session.
     *
     * @throws {AppwriteException}
     * @returns {Promise<Models.Session>}
     */
    getSession: () => account.getSession('current'),

    /**
     * Create an OAuth2 session. If there is already an active session, the new session will be attached to the logged-in
     * account. If there are no active sessions, the server will attempt to look for a user with the same email address
     * as the email received from the OAuth2 provider and attach the new session to the existing user. If no matching user
     * is found - the server will create a new user.
     *
     * @param {string} successUrl
     * @param {string} failureUrl
     * @throws {AppwriteException}
     * @returns {Promise<Models.Session>}
     */
    createOAuth2Session: (successUrl: string, failureUrl: string) =>
      account.createOAuth2Session(OAuthProvider.Auth0, successUrl, failureUrl),

    /**
     * Delete the current user session.
     *
     * @throws {AppwriteException}
     */
    logout: () => account.deleteSession('current'),
  };
}

export type User = Models.User<Models.Preferences>;

export { ID } from 'appwrite';
